<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar class="app-content-padding flex-grow-1 overflow-hidden h-100">
            <ts-page-title :title="$t('branch.pageTitle')" :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('branch.pageTitle'),
                    href: '/admin/branches'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]" />
            <div class="demo-spin-article">
                <Profile v-model="model" :validate="errors" class="tw-h-full" ref="profile" />
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div id="footer" class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef">
            <ts-button @click.prevent="$router.push({ name: 'branch' })" class="btn-gray">{{ $t("cancel") }}</ts-button>
            <ts-button color="primary" outline :waiting="waiting_new" :disabled="waiting || waiting_new"
                @click.prevent="onSaveAddNew">
                {{ $t("saveAddNew") }}</ts-button>
            <ts-button color="primary" :waiting="waiting" :disabled="waiting_new || waiting" @click.prevent="onSave">
                {{ $t("save") }}</ts-button>
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import Profile from "./components/profile.vue";
import { Errors } from "form-backend-validation";
import { mapActions } from 'vuex';

export default {
    name: "branchCreate",
    components: {
        Profile
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                company_id: null,
                branch_name_kh: null,
                branch_name_en: null,
                branch_address: null,
                branch_phone: null,
                branch_email: null,
                branch_website: null,
                image_logo: null,
                branch_code: null,
                office_rentals: [
                    {
                        contract_id: 0,
                        contract_period: null,
                        monthly_fee: null,
                        deposit_amount: null,
                        currency_id: 1,
                        owner_phone_number: null,
                        remarks: null,
                        contract_start_date: null,
                        contract_expiry_date: null,
                        attached_file: null,
                        village_code: null,
                        commune_code: null,
                        district_code: null,
                        province_code: null,
                        mime_type: null,
                        attached_file_name: null,
                        notification_receiver: []
                    }
                ],
            },
            currencies: []
        };
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("corporate/branch", [
            "getCompany",
            "getCurrency",
            "getProvince",
            "getEmployees"

        ]),
        async fetchResource() {
            this.loading = true;
            await this.getCompany();
            await this.getCurrency();
            await this.getProvince();
            await this.getEmployees();
            this.loading = false;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            let file_upload_path = await this.$refs.profile.uploadLogo();
            this.$store
                .dispatch(
                    "corporate/branch/store",
                    Object.assign({}, this.model, {
                        image_logo: file_upload_path
                    })
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            let file_upload_path = await this.$refs.profile.uploadLogo();
            this.$store
                .dispatch(
                    "corporate/branch/store",
                    Object.assign({}, this.model, {
                        image_logo: file_upload_path
                    })
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                    this.$router.push({ name: "branch" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            (this.model.branch_id = 0),
                (this.model.branch_name_kh = ""),
                (this.model.branch_name_en = ""),
                (this.model.branch_address = null),
                (this.model.branch_phone = null),
                (this.model.branch_email = null),
                (this.model.branch_website = null),
                (this.model.image_logo = ""),
                (this.model.branch_code = null),
                (this.$refs.profile.logo_file_src = require("@/assets/default-logo.jpg"));
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "BRANCH",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
